import Cv from '../components/cv';

const LOCAL_RELAY_SERVER_URL: string =
  process.env.REACT_APP_LOCAL_RELAY_SERVER_URL || '';

const LOCAL_SERVER_API: string =
  process.env.SERVER_API_URL || '';

import { useEffect, useRef, useCallback, useState } from 'react';

import { RealtimeClient } from '@openai/realtime-api-beta';
import { ItemType } from '@openai/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools/index.js';
import { instructions } from '../utils/conversation_config.js';

import { X, Zap} from 'react-feather';
import { Button } from '../components/button/Button';

import './ConsolePage.scss';

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      { url: LOCAL_RELAY_SERVER_URL }
    )
  );

  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);

  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [isConnected, setIsConnected] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [name: string]: any }>({});
  const [startMessage, setStartMessage] = useState<string[]>([]);

  const connectConversation = useCallback(async () => {
    console.log('Connecting to conversation...');
    try {


      setStartMessage([""]);
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      const wavStreamPlayer = wavStreamPlayerRef.current;

      // Set state variables
      setIsConnected(true);
      setRealtimeEvents([]);
      setItems(client.conversation.getItems());

      // Connect to microphone
      await wavRecorder.begin();

      // Connect to audio output
      await wavStreamPlayer.connect();

      // Connect to realtime API
      await client.connect();
      client.sendUserMessageContent([
        {
          type: `input_text`,
          text: `Привет!`,
          // text: `For testing purposes, I want you to list ten car brands. Number each item, e.g. "one (or whatever number you are one): the item name".`
        },
      ]);

      if (client.getTurnDetectionType() === 'server_vad') {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
      setStartMessage(["Server is available right now."]);
    } catch (e) {
      await disconnectConversation();
      setStartMessage(["Server is not available right now. Please try again later."]);
      console.error(e);
    }

  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;

    if ((value === 'none' || value === 'chat') && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }

    client.updateSession({
      modalities: ["text", ...(value !== "chat" ? ["audio"] : [])],
      instructions: 'Ты рекрутер. Твоя цель уточнить данные: имя, фамилию и университет обучения. После этого попрощаться. Если пользователь уходит от темы, ты должен его вернуть.'
    });
    client.updateSession({ voice: 'alloy' });
    client.updateSession({
      turn_detection: value === 'none' ? null : { type: 'server_vad' },
    });
    //client.updateSession({modalities: ['text']});

    if (value === 'server_vad' && client.isConnected()) {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }

  };


  const deviceIdRef = useRef<string>('Unknown Device ID');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('deviceId') || 'Unknown Device ID';
    deviceIdRef.current = id; // Update the ref value directly
  }, []);

  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  useEffect(() => {
    console.log('starting...')

    let isLoaded = true;

    const render = () => {
      if (isLoaded) {
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    client.updateSession({ instructions: instructions });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });

    // Add tools
    client.addTool(
      {
        name: 'set_memory',
        description: 'Save name, last name and university to memory.',
        parameters: {
          type: 'object',
          properties: {
            name: {
              type: 'string',
              description:
                'The name of the memory value. Always use lowercase and underscores, no other characters. In English.',
            },
            label: {
              type: 'string',
              description: 'The label of the memory value. Always use lowercase and underscores, no other characters. In English.',
            },
            value: {
              type: 'string',
              description: 'Value can be anything represented as a string',
            },
          },
          required: ['name', 'label', 'value'],
        },
      },
      async ({ name, label, value }: { [name: string]: any }) => {
        console.log('Setting memory:', name, label, value);

        // Prepare data to send to the server
        const deviceData = {
          device_id: deviceIdRef.current, // Use the ref value
          memory: { name, label, value }, // Send the updated key-value pair
        };

        try {
          // Send data to the server
          const response = await fetch(`https://api2.dupeless.com/updateMemory`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(deviceData), // Serialize data as JSON
          });

          if (!response.ok) {
            console.error(`Failed to send data to server: ${response.statusText}`);
          } else {
            console.log('Data successfully sent to server:', deviceData);
          }
        } catch (error) {
          console.error('Error while sending data to server:', error);
        }

        return { ok: true }; // Return response to the caller
      }
    );



    // handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });
    client.on('error', (event: any) => console.error(event));
    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });
    client.on('conversation.updated', async ({ item, delta }: any) => {
      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }
      if (item.status === 'completed' && item.formatted.audio?.length) {
        const wavFile = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
        item.formatted.file = wavFile;
      }
      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  useEffect(() => {
    (async () => {
      await changeTurnEndType('server_vad');
    })();
  }, []);
  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">
      <div className={'start__container'}>
        <Button
          label={isConnected ? 'End' : 'Start'}
          iconPosition={isConnected ? 'end' : 'start'}
          icon={isConnected ? X : Zap}
          buttonStyle={isConnected ? 'regular' : 'action'}
          onClick={
            isConnected ? disconnectConversation : connectConversation
          }
        />
        <div className="device-id">
          <strong>Device ID:</strong> {deviceIdRef.current}
        </div>
        <div>{startMessage[0]}</div>
        <Cv />
      </div>
    </div>
  );
}
