import React, { useEffect, useRef, useState } from 'react';

interface Memory {
  name: string;
  label: string;
  value: string;
}

interface CvData {
  device_id: string;
  memory: Memory[];
  updated_at?: string;
  matched_cvs?: MatchedCv[];
}

interface MatchedCv {
  id: string;
  matched_at: string;
  status: string;
}

const Cv: React.FC = () => {
  const [data, setData] = useState<CvData | null>(null);
  const [matchedCvs, setMatchedCvs] = useState<MatchedCv[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editableMemory, setEditableMemory] = useState<Memory | null>(null);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const deviceIdRef = useRef<string>('Unknown Device ID');

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('deviceId') || 'Unknown Device ID';
    deviceIdRef.current = id;

    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        // Fetch CV data
        const response = await fetch(
          `https://api2.dupeless.com/getMemory/${deviceIdRef.current}`
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const fetchedData: CvData = await response.json();
        setData(fetchedData);

        // Fetch matched CVs
        const matchedResponse = await fetch(
          `https://api2.dupeless.com/getMatchedVacancies/${deviceIdRef.current}`
        );

        if (!matchedResponse.ok) {
          throw new Error(`Error fetching matched CVs: ${matchedResponse.statusText}`);
        }

        const fetchedMatchedCvs: MatchedCv[] = await matchedResponse.json();
        setMatchedCvs(fetchedMatchedCvs);
      } catch (err: any) {
        setError(err.message || 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEdit = (index: number) => {
    if (!data) return;
    setEditingIndex(index);
    setEditableMemory({ ...data.memory[index] });
  };

  const handleSave = async () => {
    if (!data || editingIndex === null || !editableMemory) return;

    if (!editableMemory.name.trim() || !editableMemory.label.trim() || !editableMemory.value.trim()) {
      setError('All fields are required.');
      return;
    }

    const updatedMemory = [...data.memory];
    updatedMemory[editingIndex] = editableMemory;

    const updatedData: CvData = {
      ...data,
      memory: updatedMemory,
    };

    setData(updatedData);
    setError(null);
    setIsSaving(true);

    try {
      const response = await fetch(`https://api2.dupeless.com/updateMemory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          device_id: data.device_id,
          memory: updatedMemory[editingIndex],
        }),
      });

      if (!response.ok) {
        throw new Error(`Failed to save data: ${response.statusText}`);
      } else {
        console.log('Data successfully sent to the server:', updatedMemory[editingIndex]);
      }
    } catch (err: any) {
      setError(err.message || 'Error while saving data');
    } finally {
      setIsSaving(false);
      setEditingIndex(null);
      setEditableMemory(null);
    }
  };

  const handleCancel = () => {
    setEditingIndex(null);
    setEditableMemory(null);
    setError(null);
  };

  const handleInputChange = (field: keyof Memory, value: string) => {
    if (!editableMemory) return;
    setEditableMemory({
      ...editableMemory,
      [field]: value,
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div style={{ color: 'red' }}>Error: {error}</div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <h1>Device Memory</h1>
      <h2>Device ID: {data.device_id}</h2>
      <h3>Memory</h3>
      {data.memory.length > 0 ? (
        <ul>
          {data.memory.map((item, index) => (
            <li key={index}>
              {editingIndex === index ? (
                <div>
                  <label>
                    <strong>Name:</strong>
                    <input
                      type="text"
                      value={editableMemory?.name || ''}
                      onChange={(e) => handleInputChange('name', e.target.value)}
                    />
                  </label>
                  <br />
                  <label>
                    <strong>Label:</strong>
                    <input
                      type="text"
                      value={editableMemory?.label || ''}
                      onChange={(e) => handleInputChange('label', e.target.value)}
                    />
                  </label>
                  <br />
                  <label>
                    <strong>Value:</strong>
                    <input
                      type="text"
                      value={editableMemory?.value || ''}
                      onChange={(e) => handleInputChange('value', e.target.value)}
                    />
                  </label>
                  <br />
                  <button onClick={handleSave} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save'}
                  </button>
                  <button onClick={handleCancel} disabled={isSaving}>
                    Cancel
                  </button>
                </div>
              ) : (
                <div>
                  <strong>Name:</strong> {item.name} <br />
                  <strong>Label:</strong> {item.label} <br />
                  <strong>Value:</strong> {item.value} <br />
                  <button onClick={() => handleEdit(index)}>Edit</button>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>No memory data available.</p>
      )}
      {data.updated_at && <p>Last updated: {new Date(data.updated_at).toLocaleString()}</p>}

      <h3>Matched CVs</h3>
      {matchedCvs.length > 0 ? (
        <ul>
          {matchedCvs.map((cv) => (
            <li key={cv.id}>
              <strong>ID:</strong> {cv.id} <br />
              <strong>Matched At:</strong> {new Date(cv.matched_at).toLocaleString()} <br />
              <strong>Status:</strong> {cv.status} <br />
            </li>
          ))}
        </ul>
      ) : (
        <p>No matched CVs available.</p>
      )}
    </div>
  );
};

export default Cv;
